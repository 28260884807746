import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudents,
  fetchUsers,
  fetchTeachers,
  addUser,
} from "../../store/users/usersThunks"; // Import addUser thunk
import { fetchClasses } from "../../store/classes/classesThunks";
import { Link } from "react-router-dom";
import { useGetBranchesQuery } from "../../services";
import avatar from "../../assets/icons/user.svg";
import { SimpleLoader } from "../../components/Loading";
import toast from "react-hot-toast"; // Import react-hot-toast

function Users() {
  const [openNewUserForm, setOpenNewUserForm] = useState(false);
  const { data: branches } = useGetBranchesQuery();

  const [branchId, setBranchId] = useState(null);
  // State to manage form inputs
  const [newUser, setNewUser] = useState({
    phoneNumber: "",
    fullName: "",
    password: "",
    role: "Student", // Default role; can be changed by the admin
    gender: "Choose",
    location: "Namangan",
    birthday: "",
  });

  useEffect(() => {
    if (branches?.data?.length && !branchId) {
      const defaultBranchId = branches.data[0]._id;
      setBranchId(defaultBranchId);
    }
  }, [branches, branchId]);

  const { users, classes } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch fetchUsers action when the component mounts
    dispatch(fetchClasses({ branchId: branchId, page: 1, pageSize: 10 }));
    dispatch(fetchUsers({ page: 1, pageSize: 10 }));
    dispatch(fetchStudents({ page: 1, pageSize: 10 }));
    dispatch(fetchTeachers({ page: 1, pageSize: 10 }));
  }, [dispatch]);

  // Handle input changes
  const handleInputChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let postUser = {
      phoneNumber: newUser.phoneNumber,
      fullName: newUser.fullName,
      password: newUser.password,
      role: newUser.role,
      gender: newUser.gender,
      location: newUser.location,
      birthday: newUser.birthday,
      branch: branchId,
    };
    try {
      await dispatch(addUser(postUser)).unwrap(); // Dispatch addUser action
      toast.success("User added successfully!"); // Success notification
      setOpenNewUserForm(false); // Close the form
      dispatch(fetchUsers({ page: 1, pageSize: 10 })); // Refresh users list
      dispatch(fetchStudents({ page: 1, pageSize: 10 }));
      dispatch(fetchTeachers({ page: 1, pageSize: 10 }));
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Failed to add user."); // Error notification
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-slate-700 lg:ml-64">
      <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-16">
        <div className="grid md:grid-cols-3 gap-4 mb-4">
          {/* Users */}
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50 dark:bg-gray-800">
            {users.students.isLoading ? (
              <SimpleLoader />
            ) : (
              <div className="flex items-center gap-4">
                <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                  <img src={avatar} alt="avatar" className="w-6 h-6" />
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="text-title-md font-bold text-black dark:text-white">
                    {users.students.totalCount} ta
                  </h4>
                  <span className="text-sm font-medium text-black dark:text-white">
                    Students
                  </span>
                  <Link
                    to="/admin/users/students"
                    className="text-sm font-medium text-blue-500 dark:text-blue-500"
                  >
                    View all
                  </Link>
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50 dark:bg-gray-800">
            {users.teachers.isLoading ? (
              <SimpleLoader />
            ) : (
              <div className="flex items-center gap-4">
                <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                  <img src={avatar} alt="avatar" className="w-6 h-6" />
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="text-title-md font-bold text-black dark:text-white">
                    {users.teachers.totalCount} ta
                  </h4>
                  <span className="text-sm font-medium text-black dark:text-white">
                    Teachers
                  </span>
                  <Link
                    to="/admin/users/teachers"
                    className="text-sm font-medium text-blue-500 dark:text-blue-500"
                  >
                    View all
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          type="button"
          onClick={() => setOpenNewUserForm(!openNewUserForm)}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          New User {openNewUserForm ? "-" : "+"}
        </button>
        {openNewUserForm && (
          <form onSubmit={handleFormSubmit} className="max-w-sm mx-auto">
            <div className="mb-5">
              <label
                htmlFor="phoneNumber"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Phone Number
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={newUser.phoneNumber}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-indent"
                required
                minLength={9}
                max={9}
                placeholder="+998"
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="fullName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Full Name
              </label>
              <input
                type="text"
                name="fullName"
                value={newUser.fullName}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="role"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Gender
              </label>
              <select
                name="gender"
                value={newUser.gender}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="">Choose</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="mb-5">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                value={newUser.password}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="role"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Role
              </label>
              <select
                name="role"
                value={newUser.role}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="Student">Student</option>
                <option value="Teacher">Teacher</option>
              </select>
            </div>
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Add
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Users;
