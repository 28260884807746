import React, { useEffect, useState } from "react";
// import ChartTwo from "../../components/charts/ChartTwo";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudents,
  fetchUsers,
  fetchTeachers,
} from "../../store/users/usersThunks";
import { fetchClasses } from "../../store/classes/classesThunks";
import { useGetBranchesQuery } from "../../services";
import avatar from "../../assets/icons/user.svg";
import ClassRoom from "../../assets/icons/classroom.png";
import { SimpleLoader } from "../../components/Loading";
function Dashboard() {
  const { data: branches } = useGetBranchesQuery();
  const [branchId, setBranchId] = useState(null);
  const { users, classes } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (branches?.data?.length && !branchId) {
      const defaultBranchId = branches.data[0]._id;
      setBranchId(defaultBranchId);
    }
  }, [branches, branchId]);
  useEffect(() => {
    dispatch(fetchClasses({ branchId: branchId, page: 1, pageSize: 10 }));
    dispatch(fetchUsers({ page: 1, pageSize: 10 })); // Adjust pageSize as needed
    dispatch(fetchStudents({ page: 1, pageSize: 10 }));
    dispatch(fetchTeachers({ page: 1, pageSize: 10 }));
  }, [dispatch, branchId]);

  return (
    <div className="p-4 bg-white dark:bg-slate-700 lg:ml-64">
      <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-16">
        <div className="grid md:grid-cols-3 gap-4 mb-4">
          {/* Users */}
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50 dark:bg-gray-800">
            <div className="flex items-center gap-4">
              {!users?.students?.totalCount ? (
                <SimpleLoader />
              ) : (
                <>
                  <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                    <img src={avatar} alt="avatar" className="w-6 h-6" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <h4 className="text-title-md font-bold text-black dark:text-white">
                      {users.students.totalCount} ta
                    </h4>
                    <span className="text-sm font-medium text-black dark:text-white">
                      Students
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50 dark:bg-gray-800">
            <div className="flex items-center gap-4">
              {!users?.teachers.totalCount ? (
                <SimpleLoader />
              ) : (
                <>
                  <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                    <img src={avatar} alt="avatar" className="w-6 h-6" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <h4 className="text-title-md font-bold text-black dark:text-white">
                      {users.teachers.totalCount} ta
                    </h4>
                    <span className="text-sm font-medium text-black dark:text-white">
                      Teachers
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Classes */}
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50 dark:bg-gray-800">
            <div className="flex items-center gap-4">
              {!classes?.classes?.totalCount ? (
                <SimpleLoader />
              ) : (
                <>
                  <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                    <img src={ClassRoom} alt="avatar" className="w-6 h-6" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <h4 className="text-title-md font-bold text-black dark:text-white">
                      {classes?.classes?.totalCount} ta
                    </h4>
                    <span className="text-sm font-medium text-black dark:text-white">
                      Guruxlar
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <ChartTwo /> */}
      </div>
    </div>
  );
}

export default Dashboard;
