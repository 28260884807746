import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudents,
  fetchUserById,
  fetchUsers,
  editUser,
} from "../../../store/users/usersThunks";
import { useParams, Link } from "react-router-dom";
import { fetchClasses } from "../../../store/classes/classesThunks";
import { FaRegEdit } from "react-icons/fa";
import { GoTrash } from "react-icons/go";
import { useGetBranchesQuery } from "../../../services";
import toast from "react-hot-toast"; // Import react-hot-toast

function SingleStudent() {
  const { users, classes } = useSelector((state) => state);
  const { data: branches } = useGetBranchesQuery();
  const [branchId, setBranchId] = useState(null);
  const dispatch = useDispatch();
  const [openNewUserForm, setOpenNewUserForm] = useState(false);
  const [classs, setClass] = useState();
  const singleUser = users.singleUser?.data?.data; // Corrected data structure
  const { id } = useParams();

  // Initialize with default empty values
  const [newUser, setNewUser] = useState({
    phoneNumber: "",
    fullName: "",
    password: "",
    role: "Student", // Default role
    gender: "Choose",
    location: "Namangan",
    birthday: "",
    branch: branchId,
  });

  useEffect(() => {
    if (branches?.data?.length && !branchId) {
      const defaultBranchId = branches.data[0]._id;
      setBranchId(defaultBranchId);
    }
  }, [branches, branchId]);

  useEffect(() => {
    if (id) {
      dispatch(fetchUserById(id));
      dispatch(fetchClasses({ branchId: branchId, page: 1, pageSize: 10 }));
    }
  }, [dispatch, branchId, id]);

  useEffect(() => {
    if (singleUser) {
      setNewUser({
        phoneNumber: singleUser?.phoneNumber || "",
        fullName: singleUser?.fullName || "",
        password: "",
        role: "Student",
        gender: singleUser?.gender || "Choose",
        location: singleUser?.location || "Namangan",
        birthday: singleUser?.birthday || "",
        branch: branchId,
      });
    }
  }, [singleUser, branchId]);

  useEffect(() => {
    if (singleUser && classes.classes.data?.length) {
      const foundClass = classes.classes.data.find((classItem) =>
        classItem.students.some((student) => student._id === singleUser._id)
      );
      setClass(foundClass);
    }
  }, [singleUser, classes.classes.data]);

  const handleInputChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      let userData = {
        phoneNumber: newUser.phoneNumber,
        fullName: newUser.fullName,
        password: newUser.password,
        role: newUser.role,
        gender: newUser.gender,
        location: newUser.location,
        birthday: newUser.birthday,
        branch: newUser.branch,
      };
      await dispatch(editUser({ userId: singleUser._id, userData })).unwrap();
      toast.success("User updated successfully!"); // Success toast notification
      setOpenNewUserForm(false);
      dispatch(fetchUsers({ page: 1, pageSize: 10 }));
      dispatch(fetchStudents({ page: 1, pageSize: 10 }));
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user."); // Error toast notification
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-slate-700 lg:ml-64">
      <div className="rounded-lg dark:border-gray-700 lg:mt-16 flex-col md:mt-16 flex md:flex-row ">
        <div className="w-full flex-1 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-10 ">
          <div className="flex justify-end items-end px-4 pt-4 flex-col">
            <button
              className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
              type="button"
            >
              <FaRegEdit />
            </button>
            <button
              className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
              type="button"
            >
              <GoTrash />
            </button>
          </div>
          <div className="flex flex-col items-center pb-10">
            <img
              className="w-24 h-24 mb-3 rounded-full shadow-lg"
              src={singleUser?.avatar || "/path/to/default-avatar.jpg"}
              alt={`${singleUser?.fullName}'s profile`}
            />
            <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
              {singleUser?.fullName}
            </h5>
            <span className="text-sm text-gray-500 dark:text-gray-400">
              {singleUser?.phoneNumber}
            </span>
            <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 mt-4">
              <li className="py-3 sm:py-4">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <h2>Class:</h2>
                  {classs ? (
                    <Link
                      to={`/admin/classes/${classs._id}`}
                      className="text-white uppercase bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      {classs.name}
                    </Link>
                  ) : (
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      No class assigned
                    </span>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
        {!openNewUserForm && (
          <form
            onSubmit={handleFormSubmit}
            className="max-w-sm mx-auto flex-1 mt-10 w-full "
          >
            <div className="mb-5">
              <label
                htmlFor="phoneNumber"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Phone Number
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={newUser.phoneNumber}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                minLength={9}
                max={9}
                placeholder="+998"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="fullName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Full Name
              </label>
              <input
                type="text"
                name="fullName"
                value={newUser.fullName}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="gender"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Gender
              </label>
              <select
                name="gender"
                value={newUser.gender}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="">Choose</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                value={newUser.password}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Update Student
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default SingleStudent;
