import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudents,
  fetchTeachers,
  fetchUsers,
} from "../../../store/users/usersThunks";
import { fetchClasses } from "../../../store/classes/classesThunks";
import { Link } from "react-router-dom";
import { useGetBranchesQuery } from "../../../services";
import { SimpleLoader } from "../../../components/Loading";

function Students() {
  const { users } = useSelector((state) => state);
  const { data: branches } = useGetBranchesQuery();

  const [branchId, setBranchId] = useState(null);
  const dispatch = useDispatch();

  // State to manage the search query
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    if (branches?.data?.length && !branchId) {
      const defaultBranchId = branches.data[0]._id;
      setBranchId(defaultBranchId);
    }
  }, [branches, branchId]);
  useEffect(() => {
    dispatch(fetchClasses({ branchId: branchId, page: 1, pageSize: 10 }));
    dispatch(fetchUsers({ page: 1, pageSize: 10 }));
    dispatch(fetchStudents({ page: 1, pageSize: 10 }));
  }, [dispatch]);

  const handlePageClick = (newPage) => {
    dispatch(fetchStudents({ page: newPage, pageSize: 10 }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter students based on search query
  const filteredStudents = users.students.data.filter((student) =>
    student.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderPagination = () => {
    const { currentPage, totalPages } = users.students;
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`px-4 py-2 m-1 border rounded ${
            i === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="flex justify-center mt-4 w-full ">
        <button
          onClick={() => handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 m-1 border rounded bg-gray-200 text-gray-800 disabled:opacity-50"
        >
          Previous
        </button>
        {pages}
        <button
          onClick={() => handlePageClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 m-1 border rounded bg-gray-200 text-gray-800 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="p-4 bg-white dark:bg-slate-700 lg:ml-64">
      <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-16">
        <div className="flex flex-col gap-4 mb-4">
          <div className="flex items-center justify-start p-4 rounded-xl bg-gray-50 dark:bg-gray-800 ">
            <div className="flex items-center gap-4">
              <div className="flex h-12.5 w-12.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                {/* Your SVG icon here */}
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-title-md font-bold text-black dark:text-white">
                  {users.students.totalCount} ta
                </h4>
                <span className="text-sm font-medium text-black dark:text-white">
                  Students
                </span>
              </div>
            </div>
          </div>

          {/* Search Input */}
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by full name..."
            className="border border-gray-300 rounded px-4 py-2 mb-4"
          />

          {/* Students list */}

          {users.isLoading ? (
            <SimpleLoader />
          ) : (
            <ul className="max-w-md md:max-w-[60%] divide-y divide-gray-200 dark:divide-gray-700 border border-red-300  h-[60vh] overflow-auto p-5">
              {filteredStudents.map((student) =>
                !student?._id ? (
                  <SimpleLoader />
                ) : (
                  <li className="py-3 sm:py-4" key={student._id}>
                    <div className="flex items-center space-x-4 rtl:space-x-reverse">
                      <div className="flex-shrink-0">
                        <img
                          className="w-8 h-8 rounded-full"
                          src={student.avatar}
                          alt="Student Profile"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                          {student.fullName}
                        </p>
                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                          {student.phoneNumber}
                        </p>
                      </div>
                      <Link
                        to={`/admin/users/students/${student._id}`}
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      >
                        View
                      </Link>
                    </div>
                  </li>
                )
              )}
            </ul>
          )}

          {/* Custom Pagination */}
          {renderPagination()}
        </div>
      </div>
    </div>
  );
}

export default Students;
