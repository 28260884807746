import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { persistor, store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import Routers from "./Routers";
// import Loading from "./components/Loading";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        {/* <Loading /> */}
        <Toaster />
        <Routers />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
