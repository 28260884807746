import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudentsInClass,
  fetchStudentsNotInClass,
} from "../../../store/users/usersThunks";
import {
  fetchClassById,
  addStudentToClass,
} from "../../../store/classes/classesThunks";
import { Link } from "react-router-dom";
import { useGetBranchesQuery } from "../../../services";
import toast from "react-hot-toast"; // Import react-hot-toast

function ClassStudents({ classId, classData }) {
  const { data: branches } = useGetBranchesQuery();

  const [branchId, setBranchId] = useState(null);
  const { users } = useSelector((state) => state);
  const dispatch = useDispatch();

  // State to manage the search query
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (branches?.data?.length && !branchId) {
      const defaultBranchId = branches.data[0]._id;
      setBranchId(defaultBranchId);
    }
  }, [branches, branchId]);

  useEffect(() => {
    dispatch(fetchStudentsInClass({ page: 1, pageSize: 10 }));
    dispatch(fetchStudentsNotInClass({ page: 1, pageSize: 10 }));
  }, [dispatch]);

  const handlePageClick = (newPage) => {
    dispatch(fetchStudentsNotInClass({ page: newPage, pageSize: 10 }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    dispatch(
      fetchStudentsNotInClass({
        page: 1,
        pageSize: 10,
        search: `${event.target.value}`,
      })
    );
  };

  // Function to add student to class
  const handleAddStudent = async (studentId) => {
    try {
      await dispatch(
        addStudentToClass({
          classId,
          branchId: branchId,
          studentId,
          monthlyFee: 100, // Example monthly fee, replace it with dynamic fee calculation if needed
        })
      ).unwrap();
      toast.success("Student added successfully"); // Success toast notification
      dispatch(fetchClassById(classId));
      dispatch(fetchStudentsInClass({ page: 1, pageSize: 10 }));
      dispatch(fetchStudentsNotInClass({ page: 1, pageSize: 10 }));
    } catch (error) {
      console.error("Error adding student to class:", error);
      toast.error("Failed to add student to the class."); // Error toast notification
    }
  };

  const renderPagination = () => {
    const { currentPage, totalPages } = users.studentsNotInClass;
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`px-4 py-2 m-1 border rounded ${
            i === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="flex mt-4 w-full py-2  overflow-x-auto border">
        <button
          onClick={() => handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 m-1 border rounded bg-gray-200 text-gray-800 disabled:opacity-50"
        >
          Previous
        </button>
        {pages}
        <button
          onClick={() => handlePageClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 m-1 border rounded bg-gray-200 text-gray-800 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div className=" bg-white dark:bg-slate-700 flex-1 relative top-[64px] border md:mr-5 ">
      <div className="rounded-lg dark:border-gray-700  mt-1  overflow-auto p-2 md:p-5 border relative w-full ">
        <div className="flex flex-col gap-2 md:gap-4 mb-4  w-full ">
          <div className="flex justify-start flex-col w-full md:p-4 rounded-xl bg-gray-50 dark:bg-gray-800  top-0 h-[100px]">
            <div className="flex items-center gap-4 w-full">
              <div className="flex flex-col gap-2">
                <h4 className="text-title-md font-bold text-black dark:text-white">
                  {users.studentsNotInClass.totalCount} Students Not In any
                  Classes
                </h4>
              </div>
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search by full name..."
              className="border border-gray-300 rounded px-4 py-2 mb-4 w-1/2 md:w-full  mt-5"
            />
          </div>

          <ul className="max-w-md md:max-w-full w-full  p-2 md:p-5 divide-y divide-gray-200 dark:divide-gray-700    border ">
            {users.studentsNotInClass.data.map((student) => (
              <li className="py-2 sm:py-2" key={student._id}>
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="flex-shrink-0">
                    <img
                      className="w-8 h-8 rounded-full"
                      src={student.avatar}
                      alt="Student Profile"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                      {student.fullName}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {student.phoneNumber}
                    </p>
                  </div>
                  <Link
                    to={`/admin/users/students/${student._id}`}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    View
                  </Link>
                  <button
                    onClick={() => handleAddStudent(student._id)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Add to Class
                  </button>
                </div>
              </li>
            ))}
          </ul>

          {/* Custom Pagination */}
          {renderPagination()}
        </div>
      </div>
    </div>
  );
}

export default ClassStudents;
