import axios from "axios";
export const BASE_URL_DOMAIN = "https://lsl-school.site";
// export const BASE_URL_DOMAIN = "http://localhost:8080";
const API_BASE_URL = `${BASE_URL_DOMAIN}/api/v1`;
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: false,
});
export const BASE_URL = API_BASE_URL;
// export const BASE_URL_ADMIN = `${API_BASE_URL}/admin`;
const refreshTokenEndpoint = "/auth/renewAccessToken";
const storedRefreshToken = localStorage.getItem("refreshToken");
const refreshToken = async () => {
  try {
    console.log("Refreshing token");
    if (!storedRefreshToken) {
      console.log("No refresh token found in local storage");
      return;
    }
    const response = await api.post(refreshTokenEndpoint, {
      refreshToken: storedRefreshToken,
    });
    // console.log("response: ", response);
    const { status, data } = response;
    if (status !== 200) {
      console.log(`Failed to refresh token: status code is ${status}`);
      return;
    }
    const { accessToken, refreshToken: newRefreshToken } = data?.data || {};
    if (!accessToken) {
      console.log("Failed to refresh token: access token is missing");
      return;
    }
    if (!newRefreshToken) {
      console.log("Failed to refresh token: new refresh token is missing");
      return;
    }

    // Update tokens in local storage
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return accessToken;
  } catch (error) {
    console.log("Failed to refresh token", error.message);
    if (error.response && error.response.status === 403) {
      // Check if the token is still valid
      const tokenValid = await checkToken(storedRefreshToken);
      if (!tokenValid) {
        // Remove tokens from local storage if a 403 error occurs
        console.error(
          "Failed to refresh token: Access forbidden (403). Tokens have been removed from local storage."
        );
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userData");
      }
    } else {
      console.error(error.message);
    }
  }
};

const checkToken = async (refreshToken) => {
  try {
    // Assume checkTokenEndpoint is an endpoint to validate the token
    const response = await api.post(refreshTokenEndpoint, { refreshToken });
    return response.status === 200;
  } catch (error) {
    console.error("Error checking token:", error.message);
    return false;
  }
};

const retryOriginalRequest = async (originalRequest) => {
  const newAccessToken = await refreshToken();
  if (newAccessToken) {
    // Update the request with the new access token and retry
    originalRequest.headers["Authorization"] = `${newAccessToken}`;
    return api(originalRequest);
  } else {
    return Promise.reject(new Error("Failed to refresh token"));
  }
};

// Add a request interceptor to include credentials with every request
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `${accessToken}`;
    }
    config.withCredentials = false;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return retryOriginalRequest(originalRequest);
    }
    return Promise.reject(error);
  }
);

const initializeAuth = async () => {
  const storedRefreshToken = localStorage.getItem("refreshToken");
  if (storedRefreshToken) {
    await refreshToken();
  }
};

// Call the function to check and refresh token on website initialization
initializeAuth();

export default api;
